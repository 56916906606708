@import "components/utils/commonStyles.scss";

.Header {
  align-items: center;
  background: #000;
  display: flex;
  height: 8rem;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 6%;
}

.LogoContainer {
  align-self: center;
  margin-bottom: 5rem;

  .Logo {
    width: 21em;
    transform: rotate(-30deg);
  }
}

nav {
  margin: 0 3% 0 0;

  ul {
    list-style: none;
    margin: 0;
    overflow: visible;
    padding: 0;
    display: flex;

    li {
      a:link,
      a:visited,
      a:active {
        color: rgba(255, 255, 255, 0.9);
        text-decoration: none;
      }

      a:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .NavLink {
    @extend %game-font;
    background: #5f5f5f;
    display: block;
    font-size: 0.9rem;
    padding: 0.7rem 0.2rem 0.4rem 0.2rem;
    margin: 0 0.5rem;
    text-align: center;
    width: 11.5rem;

    &:hover {
      transition: all 0.2s ease-out;
    }
  }

  .ArtLink {
    &:hover {
      @include nav-link-bg(#1f35d2, #46cbfe);
    }

    &::before {
      @extend %nav-sprite;
      content: url("~images/omanyte_still.gif");
    }

    &:hover::before {
      content: url("~images/omanyte_animated.gif");
    }
  }

  .IconsLink {
    &:hover {
      @include nav-link-bg(#fa8f48, #fff763);
    }

    &::before {
      @extend %nav-sprite;
      content: url("~images/ampharos_still.gif");
    }

    &:hover::before {
      content: url("~images/ampharos_animated.gif");
    }
  }

  .TutorialLink {
    &:hover {
      @include nav-link-bg(#06a168, #5cff76);
    }

    &::before {
      @extend %nav-sprite;
      content: url("~images/kirlia_still.gif");
    }

    &:hover::before {
      content: url("~images/kirlia_animated.gif");
    }
  }
}

%nav-sprite {
  vertical-align: -70%;
  padding: 0 10px 0 0;
}

@media (max-width: 850px) {
  .Header {
    flex-direction: column;
    padding: 0;
    margin: 0;
    height: 100%;
  }

  .HeaderLogoContainer {
    background-image: url("~images/latios-logo.svg");
    background-size: 600px;
    background-position: center -450px;
    background-repeat: no-repeat;
    order: 1;
    height: 60px;
    display: block;
    width: 100%;
    margin-bottom: 0;

    .HeaderLogo {
      width: 100%;
      transform: rotate(-30deg);
      display: none;
    }
  }

  nav {
    order: 2;
    width: 100%;
    margin: 0;
    padding: 0;
    ul {
      flex-direction: column;
    }

    .NavLink {
      margin: 0;
      width: 100%;
      border-radius: 0;
    }
  }
}
